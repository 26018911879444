import { useEffect, useState } from "react";
import { useAuth } from "../../Providers/AuthProvider";
import axios from "axios";
import Loader from "../../Global_Components/Loader";
import "./userdashboard.css";
import { FiArrowRight } from "react-icons/fi";
import avatar from "../../Global_Components/icons/dashboardAvatar-removebg-preview.png";
import eduavtar from "../../Global_Components/icons/educationavatar-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";
import UserUtils from "../../Utils/UserUtils";
export default function UserPaymentHistory() {
  const [payments, setPayments] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [dataFromInternalPortal, setDataFromInternalPortal] = useState([]);

  const getDetailsFromInteralPortal = async () => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/user/studentDashboardDetailsFromInternalPortal",
        {
          email: currentUser.email,
        }
      )

      .then(function (response) {
        console.log(response.data);
        setDataFromInternalPortal(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const usermail = currentUser.email;
  const usernamefromMail = currentUser.displayName;

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/user/get-payment-history", {
        email: currentUser.email,
      })
      .then(function (response) {
        setPayments(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [currentUser.email]);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/user/get-payment-invoice", {
        email: currentUser.email,
      })
      .then(function (response) {
        setinvoiceNumber(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [currentUser.email]);

  useEffect(() => {
    getDetailsFromInteralPortal();
  }, [currentUser.email]);

  const getCourseName = (code) => {
    switch (code) {
      case "DMX":
        return "Digital Marketing Experiential (DMX)";
      case "AOI":
        return "Art of Influence (AOI)";
      default:
        return "No Course Opted";
    }
  };

  // Assuming payments array has at least one payment
  const usercoursefromPreviousPayments =
    payments.length > 0 ? payments[0].billingCourse : "";

  return (
    <section id="backup">
      <div id="paymentnavigate-header">
        <button
          onClick={() => {
            navigate("/user");
          }}
        >
          pay fee <FiArrowRight className="arrow-to" />
        </button>
        <div className="logout-container flex gap-1 looutref">
          <img className="my-auto w-7 invert" src="/icons/profile.png" alt="" />
          <p>
            {payments.length > 0 ? payments[0].billing_name : usernamefromMail}
          </p>

          {/* Logout Dropdown */}
          <div className="logout-dropdown">
            <p onClick={() => UserUtils.signOut()}>
              Logout <IoArrowForwardOutline />
            </p>
          </div>
        </div>
      </div>
      <section className="dashboard">
        <div className="verticle-column1">
          <div className="clippath"></div>
          <img src={avatar} alt="" />
          <div>
            <p style={{ color: "#f7d10c" }}>
              {payments.length > 0
                ? payments[0].billing_name
                : usernamefromMail}
            </p>

            <p className="mail-user-dash">{usermail}</p>
            <p>+91 {payments.length > 0 ? payments[0].billing_tel : ""}</p>
            <h3 className="course-details-lap">course details</h3>
            <p id="course-name" className="course-details-lap">
              {getCourseName(usercoursefromPreviousPayments)}
            </p>
            <p id="course-details-lap">
              Invoice Number:
              <span style={{ color: "#f7d10c" }}>{invoiceNumber}</span>
            </p>
          </div>
        </div>
        <div className="mobile-course-details">
          <h3 className="course-details-mobile">course details</h3>
          <p id="course-name" className="course-details-mobile">
            {getCourseName(usercoursefromPreviousPayments)}
          </p>
          <p className="course-details-mobile" id="course-name">
            Invoice Number:
            <span style={{ color: "#f7d10c" }}>{invoiceNumber}</span>
          </p>
        </div>
        <div className="verticle-column2">
          <div className="verticle-column2-1">
            <div>
              <h2>
                Welcome back,{" "}
                {payments.length > 0
                  ? payments[0].billing_name
                  : usernamefromMail}{" "}
              </h2>
              <p>always stay updated in your student dashboard</p>
            </div>
            <img src={eduavtar} alt="" />
          </div>
          <div className="heading-payment-history  payment-hostory-mobile">
            <h3 className="text-lg">Your Payment History</h3>
          </div>
          <div className="verticle-column2-2">
            <div className="verticle-column2-2-payment-history">
              <div className="heading-payment-history payment-history-lap">
                <h3 className="text-lg font-semibold mb-4">
                  Your Payment History
                </h3>
              </div>
              <div>
                {Array.isArray(payments) && payments.length > 0 ? (
                  <table className="">
                    <thead>
                      <tr>
                        <th>Payment Id</th>
                        <th>Name</th>
                        <th>Billing Date</th>
                        <th>Course</th>
                        <th>Payment Type</th>
                        <th>Email Id</th>
                        <th>Status</th>
                        <th>Mobile NUmber</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.map((payment) => (
                        <tr key={payment.id}>
                          <td>{payment.id}</td>
                          <td>{payment.billing_name}</td>
                          <td>{payment.trans_date}</td>
                          <td>{getCourseName(payment.billingCourse)}</td>
                          <td>{payment.paymenttype}</td>
                          <td>{payment.billing_email}</td>
                          <td>
                            <p
                              className={`${
                                payment.status === "Success"
                                  ? "text-green-700"
                                  : payment.status === "Failed"
                                  ? "text-red-600"
                                  : "text-gray-600"
                              }`}
                            >
                              {payment.status}
                            </p>
                          </td>
                          <td className="px-4 py-2 text-sm">
                            {payment.billing_tel}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : Array.isArray(payments) && payments.length === 0 ? (
                  <h1 className="text-center text-white-600">
                    No Payments Yet
                  </h1>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <div className="greeting-message">
              Thanks for choosing <span>HashPro Academy</span>! We’re here to
              guide you through your learning adventure. 🌟📚🚀
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
