import React from "react";

const PaymentRecieptLoader = () => {
  return (
    <div>
      <div className="paymentstatusfeildloader"></div>
      validating your transaction please wait..
    </div>
  );
};

export default PaymentRecieptLoader;
